import React from 'react';


import MailOutlined from '@ant-design/icons/MailOutlined';


const Page: React.FC<{}> = () => {

  return (
    <>
        <h2 style={{textAlign: "center"}}>CONTACT US</h2>

        <h3>Emails</h3>
        <p>For Outages/questions/inquiries:<a href="mailto:ops@rclec.com">ops@rclec.com <MailOutlined /></a></p>
        <p>For AUP violations and Scam reports:<a href="mailto:fraudresponse@ringcentral.com">fraudresponse@ringcentral.com <MailOutlined /></a></p>
        <p>For suspicious traffic/potential fraud calls:<a href="mailto:fraudalert@ringcentral.com">fraudalert@ringcentral.com <MailOutlined /></a></p>
        <p>For Porting Inquires:<a href="mailto:portstatus@rclec.com">portstatus@rclec.com <MailOutlined /></a></p>


        <h3><a href="https://rclec-cte.neustar.com/gateway"><b>LSR Submission</b></a></h3>
        <p>Tariff and Access Info: tariff-info@rclec.com</p>
        <p>Rural Call Completion questions: rural-call-completion@rclec.com</p>


        <h3>Outage reporting number</h3>
        <p>+1877-888-3156</p>


        <h3>LSR inquiry number</h3>
        <p>+1844-87-RCLEC</p>

        <h3>Rural Call Completion Issues</h3>
        <p>+1877-888-3156</p>
    </>
  );
}

export default Page;
