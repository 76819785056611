import React from 'react';


const Page: React.FC<{}> = () => {
  return (
    <><h1 style={{textAlign:"center"}}>Home</h1>
    <p><b>This is official site of RCLEC</b></p>
    </>
  );
}

export default Page;
