import {createBrowserRouter} from 'react-router-dom';

import AppLayout from './App';
import AboutUsPage from './pages/AboutUsPage';
import DocumentPage from './pages/DocumentPage';
import HomePage from './pages/HomePage';
import TariffsPage from './pages/TariffsPage';

const router = createBrowserRouter([
    {
        path: "/",
        element: <AppLayout />,
        children: [
            {
                path: "/",
                element: <HomePage />
            },
            {
                path: "/document",
                element: <DocumentPage />
            },
            {
                path: "/tariffs",
                element: <TariffsPage />
            },
            {
                path: "/about",
                element: <AboutUsPage />
            },
            {
                path: "*",
                element: <HomePage />
            }
        ]
    },

]);

export default router;