import React from 'react';


const Page: React.FC<{}> = () => {
  return (
    <>
      <h2 style={{textAlign: "center"}}>RCLEC Tariffs</h2>
      <p><a href={process.env.PUBLIC_URL + "/static/PA_PUC_IXC_Tariff_No_2.pdf"}>IXC Tariff</a></p>
      <p><a href={process.env.PUBLIC_URL + "/static/PA_PUC_Local_Exhange_Tariff_No_1.pdf"}>Local Exchange Tariff</a></p>
      <p><a href={process.env.PUBLIC_URL + "/static/RCLEC Inc RCC Tarriff Pages - 20170802.pdf"}>RCLEC Inc RCC Tarriff Pages - 20170802.pdf</a></p>
      <p><a href={process.env.PUBLIC_URL + "/static/RCLEC MPSC No. 1  4-5-16.pdf"}>RCLEC MPSC No. 1  4-5-16.pdf</a></p>
    </>
  );
}

export default Page;
