import React, {useState, useEffect} from 'react';

import {Layout, Menu, Card, Row, Col} from 'antd';
import type {MenuProps} from 'antd';

import {Link} from 'react-router-dom';

import AboutUsPage from './pages/AboutUsPage';
import DocumentPage from './pages/DocumentPage';
import HomePage from './pages/HomePage';
import TariffsPage from './pages/TariffsPage';


import logo from './logo.svg';
import './App.css';


const {Header, Content, Footer} = Layout;

const routKeys = ["document", "tariffs", "about"];


function App() {
  const [current, setCurrent] = useState('home');

  useEffect(() => {
    const pathname = window.location.pathname;
    const key = pathname.substring(1);
    if (routKeys.indexOf(key) !== -1) {
      setCurrent(key);
    }
  }, []);


  const items: MenuProps['items'] = [
    {
      label: <Link to="/">Home</Link>,
      key: "home",
    },
    {
      label: <Link to="/document">Documents</Link>,
      key: "document",
    },
    {
      label: <Link to="/tariffs">Tariffs</Link>,
      key: "tariffs",
    },
    {
      label: <Link to="/about">About Us</Link>,
      key: "about",
    }
  ];

  const nodes: Record<string, React.ReactNode> = {
      'home': <HomePage />,
      'document': <DocumentPage />,
      'tariffs': <TariffsPage />,
      'about': <AboutUsPage />,
  };

  return (
    <div style={{minHeight: "100%", width: "100%",  display: "flex"}}>
    <Layout className="layout">
      <Header style={{flexDirection: "row"}}>
        <Row>
          <Col span={4} style={{background: "#ffffff", height: "64px"}}>
          <img width="90%" height="90%" src={logo} className="App-logo" alt="logo" />
          </Col>
          <Col span={20}>
          <Menu selectedKeys={[current]} mode="horizontal" items={items} onClick={(e) => {
            setCurrent(e.key);
            
          }} />
          </Col>
        </Row>
      </Header>
      <Content style={{padding: "50px"}}>
        <Card style={{minHeight: "650px"}}>
          {nodes[current]}
        </Card>
      </Content>
      <Footer style={{textAlign: "center"}}>© 2015-2023 RCLEC. All rights reserved.</Footer>
    </Layout>
    </div>
  );
}

export default App;
